// import logo from './logo.svg';
import React, { useState, useEffect } from "react";
// import latestImage from './latest.jpg';
import './App.css';


function App() {
  const [currentImage, setCurrentImage] = useState(null);

  var dateTest = new Date().getTime();

  const twitchHTML = `<div id="twitch-embed"></div>`;
  const twitchJS = `<!-- Load the Twitch embed JavaScript file -->
  <script src="https://embed.twitch.tv/embed/v1.js"></script>

  <!-- Create a Twitch.Embed object that will render within the "twitch-embed" element -->
  <script type="text/javascript">
    new Twitch.Embed("twitch-embed", {
      width: 854,
      height: 480,
      channel: "jankball",
      // Only needed if this page is going to be embedded on other websites
      parent: ["naxobit.com", "www.naxobit.com"]
    });
  </script>`;

  useEffect(() => {
    const intervalId = setInterval(() => {
      var latestDate = new Date().getTime();
      setCurrentImage("/images/latest.jpg?v=" + latestDate);
    }, 5000)

    return () => clearInterval(intervalId);
  }, [])


  const sendMoveCommand = (direction) => {
    const data = { "move": direction };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    };
    fetch("https://msw2cqfq10.execute-api.us-east-1.amazonaws.com/production/move", requestOptions)
      .then(response => response.json())
      .then(res => console.log(res));
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Drive a Raspberry Pi, Solar powered Rover</h1>

        {/* <h3>Rover Controls</h3>
        <button type="submit" onClick={() => sendMoveCommand("left")}>
          Left
        </button>

        <button type="submit" onClick={() => sendMoveCommand("right")}>
          Right
        </button>

        <button type="submit" onClick={() => sendMoveCommand("forward")}>
          Forward
        </button>

        <button type="submit" onClick={() => sendMoveCommand("backward")}>
          Backward
        </button> */}

        {/* <h3>Rover View</h3>
        <div>
            <img src={currentImage} alt="latest view from rover" />
        </div> */}

        {/* <small>{dateTest}</small> */}
      </header>

      {/* <div dangerouslySetInnerHTML={{ __html: twitchHTML }} /> */}

      <div dangerouslySetInnerHTML={{ __html: twitchHTML }} />

      <div dangerouslySetInnerHTML={{ __html: twitchJS }} />

    </div>
  );
}

export default App;
